/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HubDto } from './hubDto';


export interface UserDto { 
    id?: number;
    role?: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    azureAdUpn?: string | null;
    azureAdUsername?: string | null;
    hubs?: Array<HubDto> | null;
}

