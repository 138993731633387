/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateAgreementCommand { 
    id: number;
    pointNumber?: number;
    pointPrice?: number;
    partialCompanySubsidies?: number;
    isCompanyFullychaged?: boolean;
    isExtraEnabled?: boolean;
    dishLimitNumber?: number;
    dishLimitNumberByTray?: number;
    fixedCompanySubsidies?: number;
    fixedEmployeeContribution?: number;
    orderLimitByService?: number;
    endDate?: string | null;
    startDate?: string | null;
    companyId?: number;
}

