/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShopServiceDto } from './shopServiceDto';


export interface ShopDto { 
    code?: string | null;
    id?: number | null;
    name?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    isVisible?: boolean;
    checkStock?: boolean;
    orderPlacementRange?: number | null;
    businessType?: number;
    cdpf?: string | null;
    tcposId?: number | null;
    hideIncompleteArticles?: boolean;
    siteId?: number;
    hubId?: number;
    trayLimit?: number;
    articleRatingFilterType?: number;
    shopServices?: Array<ShopServiceDto> | null;
}

