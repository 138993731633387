const CLIENT_ID = 'fcce7334-3039-4905-9a7d-fbbc56ce6821';
const API_ID = '91875ea2-060d-4fe4-81ed-96fa96cd3425';

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  msalConfig: {
    auth: {
      clientId: CLIENT_ID,
      authority: `https://login.microsoftonline.com/0e3d950c-f028-43ec-963b-c060275e6bbc`,
      redirectUri: 'https://backoffice.dev.joyfoodplus.elior.com',
      postLogoutRedirectUri: 'https://backoffice.dev.joyfoodplus.elior.com',
      loginFailedRoute: '/login-failed',
      graphApiScopes: ['User.Read', 'User.ReadBasic.All'],
      apiScopes: [`api://${API_ID}/fullaccess`],
    },
  },
  apiConfig: {
    webApiUri: 'https://api.backoffice.dev.joyfoodplus.elior.com',
  },
};
