/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BromatologicalDto } from './bromatologicalDto';
import { UnitType } from './unitType';


export interface BromatologicalValueDto { 
    bromatological?: BromatologicalDto;
    value?: number;
    unit?: UnitType;
}
export namespace BromatologicalValueDto {
}


