/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tray { 
    poste?: string | null;
    selection?: string | null;
    codeService?: string | null;
    fonction?: string | null;
    etat?: string | null;
    article?: string | null;
    client?: string | null;
    dlc?: string | null;
    dateService?: string;
}

