export * from './agreements.service';
import { AgreementsService } from './agreements.service';
export * from './articlePrices.service';
import { ArticlePricesService } from './articlePrices.service';
export * from './articles.service';
import { ArticlesService } from './articles.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './companies.service';
import { CompaniesService } from './companies.service';
export * from './companySettings.service';
import { CompanySettingsService } from './companySettings.service';
export * from './compositions.service';
import { CompositionsService } from './compositions.service';
export * from './countries.service';
import { CountriesService } from './countries.service';
export * from './customers.service';
import { CustomersService } from './customers.service';
export * from './fridges.service';
import { FridgesService } from './fridges.service';
export * from './hubs.service';
import { HubsService } from './hubs.service';
export * from './kitchenOrders.service';
import { KitchenOrdersService } from './kitchenOrders.service';
export * from './menu.service';
import { MenuService } from './menu.service';
export * from './orderItems.service';
import { OrderItemsService } from './orderItems.service';
export * from './orders.service';
import { OrdersService } from './orders.service';
export * from './reco.service';
import { RecoService } from './reco.service';
export * from './serviceTypes.service';
import { ServiceTypesService } from './serviceTypes.service';
export * from './shopClosingDate.service';
import { ShopClosingDateService } from './shopClosingDate.service';
export * from './shops.service';
import { ShopsService } from './shops.service';
export * from './sites.service';
import { SitesService } from './sites.service';
export * from './stocks.service';
import { StocksService } from './stocks.service';
export * from './tests.service';
import { TestsService } from './tests.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './wallets.service';
import { WalletsService } from './wallets.service';
export const APIS = [AgreementsService, ArticlePricesService, ArticlesService, CategoriesService, CompaniesService, CompanySettingsService, CompositionsService, CountriesService, CustomersService, FridgesService, HubsService, KitchenOrdersService, MenuService, OrderItemsService, OrdersService, RecoService, ServiceTypesService, ShopClosingDateService, ShopsService, SitesService, StocksService, TestsService, UsersService, WalletsService];
