/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DietDto } from './dietDto';
import { AllergensDto } from './allergensDto';
import { CondimentDto } from './condimentDto';


export interface CustomerPreferencesDto { 
    diets?: Array<DietDto> | null;
    allergens?: Array<AllergensDto> | null;
    condiments?: Array<CondimentDto> | null;
}

