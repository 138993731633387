/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OperationStatus } from './operationStatus';
import { StatusCode } from './statusCode';


export interface OperationResultDto { 
    entityId?: number | null;
    codeStatus?: StatusCode;
    status?: OperationStatus;
    message?: string | null;
}
export namespace OperationResultDto {
}


