/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatusCode = null | null | null | null | null | null | null | null | null;

export const StatusCode = {
    NUMBER_null: null as StatusCode,
    NUMBER_null2: null as StatusCode,
    NUMBER_null3: null as StatusCode,
    NUMBER_null4: null as StatusCode,
    NUMBER_null5: null as StatusCode,
    NUMBER_null6: null as StatusCode,
    NUMBER_null7: null as StatusCode,
    NUMBER_null8: null as StatusCode,
    NUMBER_null9: null as StatusCode
};

