/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateShopCommand { 
    id: number;
    name?: string | null;
    description?: string | null;
    code?: string | null;
    imageUrl?: string | null;
    isVisible?: boolean;
    businessType?: number;
    checkStock?: boolean;
    tcposId?: number;
    cdpf?: string | null;
    orderPlacementRange?: number;
    hideIncompleteArticles?: boolean;
    trayLimit: number;
    articleRatingFilterType?: number;
    hubId?: number | null;
    siteId?: number;
}

