/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompositionGroupDto } from './compositionGroupDto';


export interface CreateCompositionCommand { 
    companyId?: number;
    agreementId?: number;
    name?: string | null;
    tcposId?: number;
    priority?: number;
    compositionGroups?: Array<CompositionGroupDto> | null;
}

