/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItemDto } from './orderItemDto';
import { ShopDto } from './shopDto';
import { CustomerDto } from './customerDto';
import { CompartmentDto } from './compartmentDto';
import { SiteDto } from './siteDto';


export interface OrderDto { 
    id?: number | null;
    created?: string;
    deliveryDate?: string;
    customerId?: number;
    status?: number;
    serviceTypeId?: number;
    shopId?: number;
    statusName?: string | null;
    serviceTypeCode?: string | null;
    numberOfCustomers?: number | null;
    compartmentOrder?: CompartmentDto;
    customer?: CustomerDto;
    site?: SiteDto;
    shop?: ShopDto;
    orderItems?: Array<OrderItemDto> | null;
}

