import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import en from '@angular/common/locales/en';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  HttpClient,
  provideHttpClient,
} from '@angular/common/http';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalService,
  MsalInterceptor,
} from '@azure/msal-angular';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import {
  TranslateStore,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { BASE_PATH } from 'backend/openapi';
import { Configuration } from './app/api/BO_API';
import { RequestParserInterceptor } from './app/shared/interceptors/request-parser.interceptor';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function createTranslateLoader(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n/';
  return new TranslateHttpLoader(http, baseTranslateUrl, '.json');
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.msalConfig.auth.apiScopes],
    },
    loginFailedRoute: environment.msalConfig.auth.loginFailedRoute,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.apiConfig.webApiUri,
    environment.msalConfig.auth.apiScopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

// const initialNavigation =
//   !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
//     ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
//     : withDisabledInitialNavigation();

if (environment.production) {
  enableProdMode();
}

registerLocaleData(en);

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: Configuration,
      useFactory: () =>
        new Configuration({
          basePath: environment.apiConfig.webApiUri,
        }),
      multi: false,
    },
    importProvidersFrom(BrowserModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideRouter(routes /**, initialNavigation*/),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestParserInterceptor,
      multi: true,
    },
    TranslateStore,
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: BASE_PATH, useValue: environment.apiConfig.webApiUri },
  ],
});

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
