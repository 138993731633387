/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateStockModel { 
    deliveryDate?: string;
    availabilityDate?: string;
    senderCDC?: string | null;
    senderPlant?: string | null;
    destinationCDC?: string | null;
    destinationHub?: string | null;
    plateCode?: string | null;
    plateDescription?: string | null;
    quantity?: number | null;
    reservedAmount?: number | null;
    expirationDate?: string;
    productionLot?: string | null;
    qrCode?: string | null;
    ean?: string | null;
    plateType?: string | null;
}

