/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ArticleDto } from './articleDto';


export interface ArticlePriceDto { 
    id?: number;
    price?: number | null;
    points?: number | null;
    isFree?: boolean;
    posId?: number | null;
    articleId?: number;
    article?: ArticleDto;
}

