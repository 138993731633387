/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompartmentDto } from './compartmentDto';


export interface LightKitchenOrderDto { 
    id?: number | null;
    deliveryDate?: string | null;
    created?: string;
    status?: number;
    serviceTypeId?: number;
    statusName?: string | null;
    serviceTypeCode?: string | null;
    customerId?: number;
    siteId?: number;
    shopId?: number;
    shopName?: string | null;
    compartmentOrder?: CompartmentDto;
}

