/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Days } from './days';


export interface ShopServiceDto { 
    id: number;
    serviceId: number;
    serviceTypeId?: number;
    day?: Days;
    openingHour?: string | null;
    closingHour?: string | null;
    orderCutoffHour?: string | null;
    orderCutoffDay?: number | null;
    shopId: number;
}
export namespace ShopServiceDto {
}


