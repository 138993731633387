/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { DietDto } from './dietDto';
import { BromatologicalValueDto } from './bromatologicalValueDto';
import { AllergensDto } from './allergensDto';
import { ArticleIngredientDto } from './articleIngredientDto';


export interface ArticleDto { 
    id?: number;
    name?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    code?: string | null;
    price?: number | null;
    points?: number | null;
    isFree?: boolean;
    expirationDayNumber?: number;
    articlePriceId?: number;
    posId?: number;
    category?: CategoryDto;
    allergens?: Array<AllergensDto> | null;
    bromatologicalValues?: Array<BromatologicalValueDto> | null;
    diets?: Array<DietDto> | null;
    articleIngredients?: Array<ArticleIngredientDto> | null;
}

