import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { UserDto, UsersService } from 'src/app/api/BO_API';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  private activeAccountSubject = new BehaviorSubject<AccountInfo | null>(null);
  activeAccount$ = this.activeAccountSubject.asObservable();

  private allAccountSubject = new BehaviorSubject<AccountInfo[] | null>(null);
  allAccounts$ = this.allAccountSubject.asObservable();

  private meSubject = new BehaviorSubject<UserDto | null>(null);
  me$ = this.meSubject.asObservable();

  get me() {
    return this.meSubject.value;
  }

  constructor(
    http: HttpClient,
    private userService: UsersService,
    private cacheService: CacheService
  ) {
    super(http);
    if (cacheService.getItem('me'))
      this.meSubject.next(cacheService.getItem('me'));
  }

  setActiveAccount(activeAccount: AccountInfo | null) {
    this.activeAccountSubject.next(activeAccount);

    if (activeAccount != null)
      this.userService.apiUsersGet().subscribe(r => {
        this.cacheService.setItem('me', r);
        this.meSubject.next(r);
      });
  }
  setAccounts(accounts: AccountInfo[]) {
    this.allAccountSubject.next(accounts);
  }
}
