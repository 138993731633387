/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscriminatorFlags = null | null | null | null;

export const DiscriminatorFlags = {
    NUMBER_null: null as DiscriminatorFlags,
    NUMBER_null2: null as DiscriminatorFlags,
    NUMBER_null3: null as DiscriminatorFlags,
    NUMBER_null4: null as DiscriminatorFlags
};

