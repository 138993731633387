/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CompanyDto {
  id?: number | null;
  name: string;
  description?: string | null;
  contactEmail?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressZipCode?: string | null;
  addressCity?: string | null;
  addressCountryId?: number;
  addressCountryEnShortName?: string | null;
}
