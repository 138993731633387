/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenuRecipeDto } from './menuRecipeDto';
import { ServiceTypeDto } from './serviceTypeDto';


export interface MenuDto { 
    id?: number;
    code?: string | null;
    name?: string | null;
    serviceTypeId?: number;
    serviceType?: ServiceTypeDto;
    menuRecipes?: Array<MenuRecipeDto> | null;
}

