/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoryDto } from './categoryDto';
import { ArticleDto } from './articleDto';


export interface MenuRecipeDto { 
    id?: number;
    categoryId?: number;
    category?: CategoryDto;
    articleId?: number;
    article?: ArticleDto;
    forcedInclusion?: boolean;
}

