/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tray } from './tray';


export interface Distributor { 
    poste?: string | null;
    designation?: string | null;
    organisme?: string | null;
    nomOrganisme?: string | null;
    societe?: string | null;
    nomSociete?: string | null;
    numeroBloc?: string | null;
    nombreSelections?: number;
    status?: string | null;
    heureStatus?: string | null;
    cntHs?: number | null;
    trays?: Array<Tray> | null;
}

