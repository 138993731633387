/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateCompanyCommand { 
    name: string;
    description?: string | null;
    contactEmail: string;
    contactFirstName: string;
    contactLastName: string;
    addressLine1: string;
    addressLine2?: string | null;
    addressZipCode: string;
    addressCity: string;
    addressCountryId: number;
    ratingDisplayOptions?: number | null;
    siteIds?: Array<number> | null;
}

